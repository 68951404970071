import service from "../service.js";


export const serchDeviceKeep = params => {
    return service({
      method: 'POST',
      url: '/deviceKeep/serchDeviceKeep',
      params
    })
}


export const getAssistClassAll = () => {
    return service({
      method: 'GET',
      url: '/assistClass/getAssistClassAll',
    })
}


export const queryAssistClass = (id) => {
    return service({
      method: 'GET',
      url: '/assistClass/queryAssistClass',
      params: {
        id
      }
    })
  }



export const delDeviceKeep = (id) => {
    return service({
      method: 'GET',
      url: '/deviceKeep/delDeviceKeep',
      params: {
        id
      }
    })
  }

export const addDeviceKeep = (data) => {
  return service({
    method: 'POST',
    url: '/deviceKeep/addDeviceKeep',
    data
  })
}

export const updateDeviceKeep = (data) => {
  return service({
    method: 'POST',
    url: '/deviceKeep/updateDeviceKeep',
    data
  })
}


export const getDeviceKeeps = () => {
  return service({
    method: 'GET',
    url: '/deviceKeep/getDeviceKeeps',
  })
}

